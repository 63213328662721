<template lang="pug">
    .main-wrapper.procedimentos-salvar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='back()')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "caixas" }'> Procedimentos do Estabelecimento</router-link> /
                        <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

        HorarioDialog(:visible='horario_modal' :model='model' @close="closeHorarioDialog()" )

        Dialog(header='Adicionar subgrupo' :visible.sync='dialogSubgrupo' :modal='true')
            .p-grid.p-fluid.p-align-center(style='width: 400px')
                .p-col-12
                    label.form-label Subgrupo:
                    MultiSelect(v-model='model.cd_subgrupos_f' appendTo='body' :options='options.subgrupos' :filter='true' display='chip' dataKey='ie_valor' optionLabel='ds_valor' optionValue='ie_valor' placeholder='- Selecione -' )

            .ta-center.mt-2
                Button(label='Adicionar' @click='addSubgrupo()')


        Dialog.dialogApagar(header='Remover horário' :visible.sync='dialogApagar_horario' :modal='true')
            p <b>Deseja remover o horário?</b>
            span <b>Dias da semana: </b>
                span(v-for='dia in obj_horario_apagar?.ar_dias_semana') [ {{ options.dias[dia] }} ]
            <br/>
            span <b>Hora Inicial:</b> {{ obj_horario_apagar?.hr_inicio }} <br/>
            span <b>Hora Final:</b> {{ obj_horario_apagar?.hr_fim }}<br/>
            span <b>Limite Diário:</b> {{ obj_horario_apagar?.nr_vagas }}<br/>
                //- span(v-for='dia in obj_horario_apagar.ar_dias_semana') {{ options.dias[dia] }}
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='excluirHorario(obj_horario_apagar.uid)')

        ProgressBar(v-if='waiting.model || waiting.form' mode="indeterminate")
        form(v-else @submit.prevent='handleSubmit()')
            Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } procedimento`")
                    .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 900px;')

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_estabelecimento.$error }")
                            label.form-label Estabelecimento:
                            div(v-if='model.id')
                                InputText(type='text' v-model='model.nm_fantasia' :disabled='true')
                            div(v-else)
                                ProgressBar(v-if='waiting.estabelecimento' mode="indeterminate")
                                Dropdown(v-else v-model='$v.model.cd_estabelecimento.$model' :options='options.estabelecimentos' :filter='options.estabelecimentos.length > 0'
                                    dataKey='id' optionLabel='nm_fantasia' optionValue='id' placeholder='- Selecione -' )
                            .feedback--errors(v-if='submitted && $v.model.cd_estabelecimento.$error')
                                .form-message--error(v-if="!$v.model.cd_estabelecimento.required") <b>Estabelecimento</b> é obrigatório.

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_procedimento.$error }")
                            label.form-label Procedimento:
                            ProgressBar(v-if='waiting.procedimento' mode="indeterminate")
                            .p-inputgroup(v-else)
                                Button(
                                    icon="jam jam-arrow-up-right"
                                    v-tooltip.top="'Ver detalhes'"
                                    @click="verDetalhesProcedimento()"
                                    type="button" :disabled="!model.cd_procedimento"
                                )
                                InputText(v-if='model.id' type='text' v-model='model.nm_procedimento' :disabled='true')
                                Dropdown(v-else :filter='true' v-model='$v.model.cd_procedimento.$model' :options='options.procedimentos'
                                    optionLabel='text' dataKey='id' placeholder='- Selecione -' @input="onChangeProcedimento")
                                    template(#option='props')
                                        span <b>{{ props.option.textAlt }}</b><br>
                                        span {{ props.option.nm_procedimento }}
                            .feedback--errors(v-if='submitted && $v.model.cd_procedimento.$error')
                                .form-message--error(v-if="!$v.model.cd_procedimento.required") <b>Procedimento</b> é obrigatório.

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_observacao.$error }")
                            label.form-label Nome do procedimento:
                            InputText(type='text' v-model='$v.model.ds_observacao.$model')
                            .feedback--errors(v-if='submitted && $v.model.ds_observacao.$error')
                                .form-message--error(v-if="!$v.model.ds_observacao.required") <b>Nome do procedimento</b> é obrigatório.

                        .p-col-12
                            label.form-label Código Próprio:
                            InputMask(mask="999999?99" v-model='model.cd_codigo_proprio'
                                placeholder="Usado somente no estabelecimento (Opcional)")

                        .p-col-12
                            label.form-label Nome no estabelecimento:
                            InputText(type='text' v-model='model.ds_nome_proprio')

                        .p-col-12
                            label.form-label Código de integração Shift:
                            InputText(type='text' v-model='model.ds_mnemonico')

                        .p-col-12(:class="[`p-md-${!!model.id ? '4' : '6'}`, { 'form-group--error': submitted && $v.model.nr_valor_negociado.$error }]")
                            label.form-label Valor negociado:
                            .p-inputgroup
                                span.p-inputgroup-addon R$
                                span(style='flex: 1')
                                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                                        v-model='$v.model.nr_valor_negociado.$model' locale='pt-BR' :auto-decimal-mode='true'
                                        :value-range='{ min: 0, max: 999999 }')
                            .feedback--errors(v-if='submitted && $v.model.nr_valor_negociado.$error')
                                .form-message--error(v-if="!$v.model.nr_valor_negociado.required") <b>Valor Negociado</b> é obrigatório.

                        .p-col-12(:class="[`p-md-${!!model.id ? '4' : '6'}`, { 'form-group--error': submitted && $v.model.nr_valor_tarifa.$error }]")
                            label.form-label Tarifa MedClub:
                            .p-inputgroup
                                span.p-inputgroup-addon R$
                                span(style='flex: 1')
                                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                                        v-model='$v.model.nr_valor_tarifa.$model' locale='pt-BR' :auto-decimal-mode='true'
                                        :value-range='{ min: 0, max: 999999 }')
                            .feedback--errors(v-if='submitted && $v.model.nr_valor_tarifa.$error')
                                .form-message--error(v-if="!$v.model.nr_valor_tarifa.required") <b>Valor Negociado</b> é obrigatório.

                        .p-col-12.p-md-4.ta-left(v-if="!!model.id")
                            .p-field-checkbox
                                label.form-label Guardar valor antigo:
                                InputSwitch.ml-2(v-model='model.ie_guardar_valor_antigo')
                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ie_tipo_procedimento.$error }")
                            label.form-label Tipo de Procedimento:
                            Dropdown(
                                v-model='$v.model.ie_tipo_procedimento.$model'
                                :options='options.tiposProcedimento'
                                dataKey='ie_valor' optionLabel='ds_valor'
                                optionValue='ie_valor' placeholder='- Selecione -'
                            )
                            .feedback--errors(v-if='submitted && $v.model.ie_tipo_procedimento.$error')
                                .form-message--error(v-if="!$v.model.ie_tipo_procedimento.required") <b>Tipo de Procedimento</b> é obrigatório.

                        //- .p-col-12
                        //-     label.form-label Subgrupo:
                        //-     ProgressBar(v-if='waiting.subgrupo' mode="indeterminate")
                        //-     MultiSelect(v-else v-model='model.cd_subgrupos' :options='options.subgrupos' :filter='true' :disabled='disableSubgrupo' dataKey='value' optionLabel='text' optionValue='value' placeholder='- Selecione -' )
                        .p-col-12
                            Panel.my-2(header='Subgrupos:' :toggleable='true')
                                DataTable(
                                    v-if="model.cd_subgrupos.length" 
                                    :value="model.cd_subgrupos" 
                                    editMode='cell' 
                                    class='editable-cells-table'
                                    @cell-edit-complete='onCellEditComplete'
                                    removableSort 
                                    :filters="filters_table"
                                    style="max-height: 400px; overflow-y: auto;"
                                )
                                    Column(headerStyle='width: 7%;' bodyStyle='text-align: center;')
                                        template(#body='props')
                                            ProgressSpinner.waitingStatus(v-if='waitingStatusSubgrupo === props.data.id' strokeWidth='6')
                                            .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatusSubgrupo(props.data)')
                                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                                    Column(headerStyle='width: 30%;' bodyStyle='text-align: left;' field='ds_valor' header='Subgrupo' filterMatchMode="contains" sortable)
                                        template(#filter)
                                            InputText(
                                                type="text"
                                                v-model="filters_table['ds_valor']"
                                                class="p-column-filter"
                                                placeholder="Subgrupos"
                                            )
                                    Column(headerStyle='width: 30%;' bodyStyle='text-align: left;' field='ds_mnemonico_subgrupo' header='Código de Integração Shift')
                                        template(#body='props')
                                            .ta-center
                                                InputText(
                                                    type='text' 
                                                    v-model='props.data.ds_mnemonico_subgrupo'
                                                    @input="setValorMnemonico(props.data.ie_valor, props.data.ds_mnemonico_subgrupo)"
                                                    :disabled='false')
                                    Column(headerStyle='width: 12%; text-align: center' field='nr_valor_negociado_f' header='Valor Negociado')
                                        template(#editor='props')
                                            currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                                v-model="props.data['nr_valor_negociado']"
                                                locale='pt-BR'
                                                :auto-decimal-mode='true'
                                                :value-range='{ min: 0 }')
                                    Column(headerStyle='width: 12%; text-align: center' field='nr_valor_tarifa_f' header='Tarifa Medclub')
                                        template(#editor='props')
                                            currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                                v-model="props.data['nr_valor_tarifa']"
                                                locale='pt-BR'
                                                :auto-decimal-mode='true'
                                                :value-range='{ min: 0 }')
                                    Column(headerStyle='width: 12%; text-align: center' header='Valor Total')
                                        template(#body='props')
                                            p {{ totalTarifas(props.data) }}
                                    Column(headerStyle='width: 12%; text-align: center' header="Ações")
                                        template(#body='props')
                                            .ta-center
                                                Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                                    v-tooltip.top="'Remover'"
                                                    icon="jam jam-minus-circle"
                                                    type="button"
                                                    @click="removeSubgrupo(props.data.ie_valor)"
                                                )
                                .p-grid.p-fluid.p-justify-center.mt-2
                                    .p-col-3
                                    .p-col-5.mt-2
                                        Button(label='Adicionar Subgrupo' type="button"  icon="pi pi-plus" iconPos="left" @click='openDialogSubgrupo()')
                                    .p-col-4(style='display: flex; flex-direction: column; align-items: end')
                                        label.form-label Venda genérica:
                                        InputSwitch(v-model='model.ie_vende_generico')
                        .p-col-12(v-if='model.ie_tipo_procedimento == "CO"')
                            MultiSelectEspecialidades(
                                @lista-especialidades='mudaEspecialidades'
                                :preSelected = 'model.cd_especialidades'
                            )

                        .p-col-12
                            label.form-label Grupo:
                            SelectButton(
                                v-model='model.ie_grupo'
                                :options='options.grupos'
                                optionValue='ie_valor'
                                dataKey='ie_valor'
                                optionLabel='ds_valor'
                            )
                        .p-col-8
                            label.form-label Tipo de Agenda:
                            Dropdown(v-model='model.ie_tipo_agenda' :options='options.tipo_agenda' showClear
                                    dataKey='id' optionLabel='label' optionValue='value' placeholder='- Selecione -' )
                        .p-col-4.p-md-4.ta-left
                            .p-field-checkbox(style='justify-content: space-between;')
                                label.form-label Horário completo:
                                InputSwitch(v-model='model.ie_horario_completo' :disabled='!["ALI", "ALL"].includes(model.ie_tipo_agenda)')

                        .p-col-12
                            Panel(header='Horários de Realização' :toggleable='true'  mode="indeterminate")
                                DataTable(:value="model.hr_atendimento" v-if='model.hr_atendimento.length > 0')
                                    Column(headerStyle='width: 20%' field='ar_dias_semana' header='Dia da Semana')
                                        template(#body='props')
                                            ul.ta-center
                                                li(v-for='dia in props.data?.ar_dias_semana') {{options.dias[dia]}}

                                    Column(headerStyle='width: 10%' field='hr_inicio' header='Horário Inicial')
                                    Column(headerStyle='width: 10%' field='hr_fim' header='Horário Final')
                                    Column(headerStyle='width: 10%' field='nr_vagas' header='Limite Diário')
                                    Column(headerStyle='width: 10%' field='' header='Ações')
                                        template(#body='props')
                                            .ta-center
                                                Button.p-button-raised.p-button-rounded.mr-1(
                                                    type='button'
                                                    v-tooltip.top="'Visualizar'"
                                                    icon="jam jam-write"
                                                    @click='editarHorario(props.data.uid)' )
                                                Button.p-button-danger.p-button-raised.p-button-rounded(icon="jam jam-trash" v-tooltip.top="'Remover'" type='button' @click='oepnDialogRemoverHorario(props.data)')
                                .ta-center.mt-2
                                    Button(label='Adicionar Horário' type="button"  icon="pi pi-plus" iconPos="left" style='max-width: 50%' @click='horario_modal = true')

                        .p-col-12
                            label.form-label Informações de atendimento:
                            Textarea.textarea-informacoes(v-model='model.ds_informacoes' placeholder='(Opcional)' :autoResize="false" rows="5")

                        .p-col-12
                            label.form-label Preparo do Procedimento:
                            Textarea.textarea-preparo(v-model='model.ds_preparo' placeholder='(Opcional)' :autoResize="true" rows="5")

                        .p-col-12
                            .p-grid
                                .p-col-12.p-md-4.mb-4.ta-center
                                    label.form-label Permite retorno:
                                    InputSwitch(v-model='model.ie_permite_retorno')

                                .p-col-12.p-md-6.mb-6
                                    label.form-label Máx. dias para retorno:
                                    InputNumber(v-model='model.qtd_dias_retorno' :disabled='!model.ie_permite_retorno' mode='decimal' :min="0")

                        .p-col-6.p-md-3.mb-4.ta-center
                            label.form-label Hora marcada:
                            InputSwitch(v-model='model.ie_hora_marcada')

                        .p-col-6.p-md-3.mb-4.ta-center
                            label.form-label Telemedicina:
                            InputSwitch(v-model='model.ie_telemedicina')

                        .p-col-6.p-md-3.mb-4.ta-center
                            label.form-label Agenda Restrita:
                            InputSwitch(v-model='model.ie_restrito')

                        .p-col-6.p-md-3.mb-4.ta-center
                            label.form-label Status:
                            InputSwitch(v-model='model.ie_status')

                        .p-col-12(v-if="model.id")
                            .p-grid
                                .p-col-6
                                    span <b>Usuario que criou: </b> {{ model.nm_usuario_cri }}
                                .p-col-6
                                    span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                .p-col-6
                                    span <b>Usuario que editou: </b> {{ model.nm_usuario_edi }}
                                .p-col-6
                                    span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                        .p-col-12.ta-center.mt-2
                            Button(label='Salvar' type="submit" style='max-width:120px')

</template>

<style lang="scss">
    .p-multiselect-panel {
        position: absolute;
        z-index: 10000;
    }
    .procedimentos-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .form-subgrupo {
            label, div {
                color: #db5540
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .textarea-informacoes,
        .textarea-preparo {
            height: 200px !important;
            overflow-y: auto !important;
            resize: none;
        }
        .p-inputgroup {
            .p-dropdown {
                max-width: 95%;
            }
        }
        .spinner-small {
            max-width: 23px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import DataView from 'primevue/dataview'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import InputNumber from 'primevue/inputnumber'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import InputSwitch from 'primevue/inputswitch'
    import Textarea from 'primevue/textarea'
    import Dialog from 'primevue/dialog'
    import moment from 'moment'
    import MultiSelect from 'primevue/multiselect'
    import HorarioDialog from './HorarioDialog.vue'
    import MultiSelectEspecialidades from './../CustomComponents/MultiSelectEspecialidades.vue'
    import { ProcedimentosEstabelecimento, Estabelecimento, Procedimentos } from './../../middleware'
    import { required } from 'vuelidate/lib/validators'
	import DominioValor from "@/middleware/controllers/DominioValor";
    import { toastError } from "@/utils";
    import _ from 'lodash'
    import { maxLength } from 'vuelidate/lib/validators'
import { formatPrice } from '../../utils'



    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, SelectButton, Dropdown, Textarea, Tooltip, InputSwitch, MultiSelectEspecialidades, InputNumber, DataView, Paginator, DataTable, Column, HorarioDialog, MultiSelect, Dialog
        },
        directives: { tooltip: Tooltip },
        created () {
			this.getDominios();
            let id = Number.isNaN(this.$route.params.id) ? 0 : parseInt(this.$route.params.id)
            if (id !== 0) {
                this.getModel(id);
            } else {
                this.getEstabelecimentos();
                this.getProcedimentos();
            }
        },
        data () {
            return {
                model: {
                    cd_especialidades:[],
                    hr_atendimento: [],
                    obj_horario: null,
                    nm_fantasia: '',
                    next_uid: 0,
                    nm_procedimento: '',
                    cd_procedimento: null,
                    cd_subgrupos: [],
                    cd_subgrupos_f: [],
                    cd_estabelecimento: null,
                    cd_codigo_proprio: '',
                    ds_observacao: '',
                    ds_informacoes: '',
                    ds_preparo: '',
                    ds_nome_proprio: '',
                    ie_tipo_procedimento: '',
                    ie_tipo_agenda: '',
                    ie_horario_completo: true,
                    ie_hora_marcada: false,
                    ie_telemedicina: false,
                    ie_restrito: false,
                    nr_valor_negociado: 0,
                    nr_valor_tarifa: 0,
                    ie_status: false,
                    ie_grupo: null,
                    ie_guardar_valor_antigo: false,
                    ie_permite_retorno: true,
                    ie_vende_generico: true,
                    qtd_dias_retorno:  0,
                    nm_usuario_cri: '',
                    nm_usuario_edi: '',
                    dt_atualizado: '',
                    dt_criado: '',
                    ds_mnemonico: null

                },
                options: {
                    dias_da_semana: [],
                    dias: {},
                    estabelecimentos: [],
                    procedimentos: [],
                    subgrupos: [],
                    grupos: [],
                    tipo_agenda: [],
					tiposProcedimento: [],
                },
                waiting: {
                    model: false,
                    form: false,
                    estabelecimento: false,
                    procedimento: false,
                    subgrupo: false
                },
                filters_table: {},
                horario_modal: false,
                horario_modal_edicao: null,
                dialogApagar_horario: false,
                waitingStatusSubgrupo: false,
                dialogSubgrupo: false,
                obj_horario_apagar: null,
                disableSubgrupo: false,
                waitingApagar: false,
                submitted: false,
            }
        },
        watch: {
            'model.ie_permite_retorno': function(val) {
                this.model.qtd_dias_retorno = val ? 0 : null
            },
            'model.ie_tipo_agenda': function(val) {
                if(!["ALI", "ALL"].includes(val))
                    this.model.ie_horario_completo = true
            },
            'model.nm_procedimento': function(val) {
            if(isNaN(this.$route.params.id)) this.model.cd_procedimento = val
            },
            'model.cd_procedimento': function(val) {
                this.waitingSubgrupo = true
                if(!this.model.id) {
                    Procedimentos.find(this.model.id ? val : val.id).then(response => {
                        if (response.status == 200) {
                                response.data.cd_subgrupos.forEach(subgrupo => {
                                    this.options.subgrupos.push({
                                        ie_valor: subgrupo.ie_valor,
                                        ds_valor: `${ subgrupo.ds_valor }`,
                                        ie_status:  subgrupo.ie_status,
                                        ds_subgrupo: subgrupo.ds_valor,
                                        id: subgrupo.ie_valor
                                    })
                                })
                            }
                        this.waitingSubgrupo = false
                        if(response.data.cd_subgrupos.length > 0) this.disableSubgrupo = false
                        else this.disableSubgrupo = true
                        console.log("TAMANHO:", response.data)

                    })
                }
            }
        },
        validations () {
            return {
                model: {
                    cd_estabelecimento: { required },
                    cd_procedimento: { required },
                    nr_valor_negociado: { required },
                    nr_valor_tarifa: { required },
                    ds_observacao: { required },
                    ie_status: { required },
                    ie_tipo_procedimento: { required },
                    ds_mnemonico: {maxLength: maxLength(10)}
                }
            }
        },
        methods: {
            mudaEspecialidades(dado) {
                this.model.cd_especialidades = dado;
            },
            handleSubmit () {
                this.submitted = true;

                this.$v.$touch();

                if (this.$v.$invalid) return 0;

                // if (this.$v.$invalid || (this.options.subgrupos.length > 0 && this.model.cd_subgrupos.length == 0)) {
                //     this.$toast.success('Selecione pelo menos 1 subgrupo.', { duration: 3000 });
                //     return 0
                // }
                if(["ALI", "ALL"].includes(this.model.ie_tipo_agenda) && this.model.hr_atendimento.length == 0) {
                    this.$toast.error('Insira os horários de realização do procedimento.', { duration: 3000 });
                    return
                }
                let dataSend = Object.assign({}, this.model)
                dataSend.qtd_dias_retorno = dataSend.qtd_dias_retorno || 0

                if (! dataSend.id) {
                    dataSend.cd_procedimento = dataSend.cd_procedimento.id;
                }
                if(dataSend.cd_subgrupos_f) delete dataSend.cd_subgrupos_f
                if(dataSend.hr_atendimento) {
                    dataSend.hr_atendimento.forEach(item  => {
                        if(item.id == null) delete(item.id)
                        if(item.uid) delete(item.uid)
                    })
                }

                this.waiting.form = true;
                ProcedimentosEstabelecimento.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'procedimentos-estabelecimento' });
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 });
                    } else {
                        toastError(this, response);
                    }
                    this.waiting.form = false;
                });
            },
            back () {
                this.$router.go(-1);
            },
            getModel(id) {
                this.waiting.model = true;
                ProcedimentosEstabelecimento.find(id).then(response => {
                    if (response.status === 200) {
                        let keys = Object.keys(this.model);
                        keys.forEach(key => {
                            if (key == "hr_atendimento") {
                                this.model[key] = response.data[key].map(
                                    item => {
                                        let auxItem = {
                                            ...item,
                                            hr_inicio: item?.hr_inicio.slice(
                                                0,
                                                -3
                                            ),
                                            hr_fim: item?.hr_fim.slice(
                                                0,
                                                -3
                                            ),
                                            nr_vagas: item?.nr_vagas,
                                            uid: this.model.next_uid,
                                            ar_dias_semana: item.ar_dias_semana,
                                            ar_dias_semana_nome: item.ar_dias_semana.map(i => {
                                                return this.options.dias[i]
                                            })

                                        };
                                        this.model.next_uid += 1;
                                        return auxItem;
                                    }
                                );
                            } else this.model[key] = response.data[key]

                        });
                        this.model.id = id;
                        this.model.nr_valor_negociado = parseFloat(this.model.nr_valor_negociado);
                        this.model.nr_valor_tarifa = parseFloat(this.model.nr_valor_tarifa);
                        this.model.dt_criado_f = moment(this.model.dt_criado).format('DD/MM/YYYY');
                        if (this.model.dt_atualizado){
                            this.model.dt_atualizado_f = moment(this.model.dt_atualizado).format('DD/MM/YYYY');
                        }
                        if(this.model.cd_subgrupos.length > 0){
                            this.model.cd_subgrupos_f = this.model.cd_subgrupos.map(item => item.ie_valor)
                            this.model.cd_subgrupos.forEach(item => {
                                item.nr_valor_negociado_f = item.nr_valor_negociado ? formatPrice(item.nr_valor_negociado) : null
                                item.nr_valor_tarifa_f = item.nr_valor_tarifa ? formatPrice(item.nr_valor_tarifa) : null
                            })
                        }
                        this.options.subgrupos = response.data.cd_subgrupos_procedimentos.map(subgrupo => ({
                            ie_valor: subgrupo.ie_valor,
                            ds_valor: subgrupo.ds_valor ,
                            ie_status: subgrupo.ie_status

                        }))
                        if(response.data.cd_subgrupos_procedimentos.length == 0) this.disableSubgrupo = true
                        this.model.cd_subgrupos = response.data.cd_subgrupos?.map((i) =>
                            i
                        )
                    }
                    //console.log("HABALOU HABLOU SACUDIU", this.model)
                    this.waiting.model = false;
                });

            },
            getEstabelecimentos() {
                this.waiting.estabelecimento = true;
                Estabelecimento.findAllClean().then(response => {
                    this.waiting.estabelecimento = false;
                    if (response.status === 200) {
                        this.options.estabelecimentos = response.data;
                    }
                });
            },
			getProcedimentos() {
                this.waiting.procedimento = true;
                Procedimentos.findAll().then(response => {
                    if (response.status === 200) {
                        this.options.procedimentos = response.data.map(procedimento => {
                            return {
                                ...procedimento,
                                text: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento } / ${ procedimento.nm_procedimento }`,
                                textAlt: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento }`,
                            }
                        });
                    }
                    this.waiting.procedimento = false;
                });
            },
            getDominios() {
				DominioValor.findAll({ds_mnemonico: ['TABELA_PRECO_TIPO_PROCEDIMENTO', 'TABELA_PRECO_GRUPO', 'DIAS_SEMANA', 'TABELA_PRECO_TIPO_AGENDA']}).then(response => {
					if (response.status === 200) {
						this.options.tiposProcedimento = response.data['TABELA_PRECO_TIPO_PROCEDIMENTO']?.['valores'];
						this.options.grupos = response.data['TABELA_PRECO_GRUPO']?.['valores'];
                        this.options.dias_da_semana = response.data['DIAS_SEMANA']?.['valores'];
                        this.options.dias = response.data['DIAS_SEMANA']?.['valores'].reduce((acc, item) => {
                            acc[item.ie_valor] = item.ds_valor;
                            return acc;
                        }, {});
                       /*  response.data['DIAS_SEMANA']?.['valores'].forEach(item => {
                            this.options.dias[+item.ie_valor] = item.ds_valor
                        }) */
                        this.options.tipo_agenda = response.data['TABELA_PRECO_TIPO_AGENDA'].valores?.map(item => ({
                            value: item.ie_valor,
                            label: item.ds_valor
                        }));
					}
				});
			},
            verDetalhesProcedimento() {
                const cdProcedimento = this.model.cd_procedimento?.id || this.model.cd_procedimento;
                window.open(`/procedimentos/salvar/${ cdProcedimento }/`, '_blank');
            },
            onChangeProcedimento(value) {
                if (value) this.model.ds_observacao = value.ds_procedimento;
            },
            closeHorarioDialog() {
                this.horario_modal = false;
            },
            openHorarioDialog() {
                this.horario_modal = true;
            },
            oepnDialogRemoverHorario(obj) {
                console.log(obj)
                this.obj_horario_apagar = obj
                this.dialogApagar_horario = true

            },
            openDialogSubgrupo() {
                this.dialogSubgrupo = true
            },
            addSubgrupo(){
                this.options.subgrupos.forEach(item => {
                    let aux = {
                            ie_valor: item.ie_valor,
                            ie_status: item.ie_status,
                            ds_valor: item.ds_valor,
                            ds_mnemonico: item.ds_mnemonico,
                            nr_valor_negociado: item.nr_valor_negociado,
                            nr_valor_tarifa: item.nr_valor_tarifa
                        }
                    
                    if(this.model.cd_subgrupos_f.includes(item.ie_valor)){
                        this.model.cd_subgrupos.push(aux)
                    }

                })
                this.model.cd_subgrupos = _.uniqBy(this.model.cd_subgrupos, 'ie_valor')
                this.dialogSubgrupo = false
            },
            removeSubgrupo(index) {
                const auxIndex = this.model.cd_subgrupos.findIndex(
                    item => item.ie_valor == index
                );
                this.model.cd_subgrupos.splice(auxIndex, 1);
            },

            alterarStatusSubgrupo(data) {
                this.waitingStatusSubgrupo = true
                data.ie_status = ! data.ie_status
                this.waitingStatusSubgrupo = false
            },
            excluirHorario(uid) {
                this.waitingApagar = true
                const auxIndex = this.model.hr_atendimento.findIndex(
                    item => item.uid == uid
                );
                this.model.hr_atendimento.splice(auxIndex, 1);
                this.dialogApagar_horario = false
                this.waitingApagar = false
            },
            editarHorario(uid) {
                const auxIndex = this.model.hr_atendimento.findIndex(
                    item => item.uid == uid
                );
                this.model.obj_horario = {
                    ar_dias_semana: this.model.hr_atendimento[auxIndex]
                        .ar_dias_semana,
                    id: this.model.hr_atendimento[auxIndex].id,
                    hr_inicio: this.model.hr_atendimento[auxIndex].hr_inicio,
                    hr_fim: this.model.hr_atendimento[auxIndex].hr_fim,
                    nr_vagas: this.model.hr_atendimento[auxIndex].nr_vagas,
                    uid: uid
                };
                this.horario_modal = true;
            },
            setValorMnemonico(ie_valor, valor){
                const encontrado = this.model.cd_subgrupos.find(item => item.ie_valor === ie_valor);
                encontrado.ds_mnemonico = valor
            },
            onCellEditComplete (ev) {
				if (formatPrice(ev.data.nr_valor_negociado) !== ev.data.nr_valor_negociado_f) {					
                        ev.data.nr_valor_negociado_f = ev.data.nr_valor_negociado ? formatPrice(ev.data.nr_valor_negociado) : null
					}
				if (formatPrice(ev.data.nr_valor_tarifa) !== ev.data.nr_valor_tarifa_f){
                        ev.data.nr_valor_tarifa_f = ev.data.nr_valor_tarifa ? formatPrice(ev.data.nr_valor_tarifa) : null
                } 
                ev.data.nr_valor_total = ev.data.nr_valor_negociado + ev.data.nr_valor_tarifa
                ev.data.nr_valor_total_f = formatPrice(ev.data.nr_valor_negociado + ev.data.nr_valor_tarifa)
			},
            totalTarifas(data) {
                return (data.nr_valor_tarifa + data.nr_valor_negociado) ? formatPrice(data.nr_valor_tarifa + data.nr_valor_negociado) : null
            },
        }
    }
</script>
