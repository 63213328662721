<template lang="pug">
    Dialog(
        class="adicionar-area-atuacao"
        header="Adicionar horário de realização"
        :visible.sync="show"
        :modal="true"
        :contentStyle="{ overflowY: 'auto !important' }"
        @hide="reset()"
        )
        .p-grid.p-align-end.p-fluid
            .p-col-12(:class="{ 'form-group--error': submitted && $v.ar_dias_semana.$error }")
                label.form-label Dias da Semana:
                SelectButton(
                    v-model="ar_dias_semana"
                    :options="dias_semana"
                    :multiple="true"
                    optionValue="value"
                    dataKey="value"
                    optionLabel="label"
                    style="display: flex; flex-direction: row;"
                )
                .feedback--errors(v-if="submitted && $v.ar_dias_semana.$error")
                    .form-message--error(v-if="!$v.ar_dias_semana.minLength") Deve ter pelo menos 2 caracteres.
                    .form-message--error(v-if="!$v.ar_dias_semana.required") Campo obrigatório.
            .p-col-4.p-fluid(:class="{ 'form-group--error': (submitted && $v.hr_inicio.$error) || start_error }")
                label.form-label Hora Inicial:
                InputMask(
                    type="tel"
                    v-model="hr_inicio"
                    mask="99:99"
                    placeholder="hh:mm"
                    @change="handleStart()"
                )
                .feedback--errors(v-if="(submitted && $v.hr_inicio.$error) || start_error")
                    .form-message--error(v-if="!$v.hr_inicio.required") Campo obrigatório.
                    .form-message--error(v-if="start_error") {{ start_error }}
            .p-col-4.p-fluid(:class="{ 'form-group--error': (submitted && $v.hr_fim.$error) || end_error }")
                label.form-label Hora Final:
                InputMask(
                    type="tel"
                    v-model="hr_fim"
                    mask="99:99"
                    placeholder="hh:mm"
                    @change="handleEnd()"
                )
                .feedback--errors(v-if="(submitted && $v.hr_fim.$error) || end_error")
                    .form-message--error(v-if="!$v.hr_fim.required") Campo obrigatório.
                    .form-message--error(v-if="end_error") {{ end_error }}
            .p-col-4.p-fluid(:class="{ 'form-group--error': (submitted && $v.hr_fim.$error) || end_error }")
                label.form-label Limite Diário:
                InputNumber(
                    :disabled='model.ie_tipo_agenda != "ALL"'
                    mode="decimal"
                    :useGrouping='false'
                    v-model="nr_vagas"
                )
                .feedback--errors(v-if="(submitted && $v.hr_fim.$error) || end_error")
                    .form-message--error(v-if="!$v.hr_fim.required") Campo obrigatório.
                    .form-message--error(v-if="end_error") {{ end_error }}
            .p-col-12
                .ta-center
                    ProgressSpinner.waiting-add(v-if="waiting" strokeWidth="6")
                    Button(
                        v-else
                        label="Adicionar Horário"
                        icon="jam jam-plus"
                        @click="save()"
                        style='width: 50%'
                        type="button"
                    )

</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputMask from "primevue/inputmask";
import ProgressSpinner from "primevue/progressspinner";
import SelectButton from "primevue/selectbutton";
import InputNumber from 'primevue/inputnumber';
import { minLength, required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
    components: { Button, Dialog, ProgressSpinner, SelectButton, InputMask, InputNumber },
    props: ["visible", "model"],
    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) this.$emit("close");
            }
        }
    },
    watch: {
        "model.obj_horario": function(obj) {
            if (obj && obj.hr_inicio) {
                this.ar_dias_semana = this.model.obj_horario.ar_dias_semana;
                this.hr_fim = this.model.obj_horario.hr_fim;
                this.hr_inicio = this.model.obj_horario.hr_inicio;
                this.nr_vagas = this.model.obj_horario.nr_vagas
            }
        }
    },
    data() {
        return {
            submitted: false,
            waiting: false,
            ar_dias_semana: [],
            hr_fim: "",
            hr_inicio: "",
            nr_vagas: 0,
            start_error: null,
            end_error: null,
            dias_semana: [
                { value: 0, label: "Domingo" },
                { value: 1, label: "Segunda" },
                { value: 2, label: "Terça" },
                { value: 3, label: "Quarta" },
                { value: 4, label: "Quinta" },
                { value: 5, label: "Sexta" },
                { value: 6, label: "Sabado" }
            ]
        };
    },
    validations() {
        return {
            hr_inicio: { required },
            hr_fim: { required },
            ar_dias_semana: { required, minLength: minLength(1) }
        };
    },
    methods: {
        save() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return 0;
            var invalid =
                this.handleEnd() != null || this.handleStart() != null;
            const auxList = this.model.obj_horario
                ? this.model.hr_atendimento.filter(
                      item => item.uid != this.model.obj_horario.uid
                  )
                : this.model.hr_atendimento;
            auxList.forEach(item => {
                item.ar_dias_semana.forEach(i => {
                    if (this.ar_dias_semana.some(it => it == i)) {
                        if (
                            !(
                                this.hr_inicio < item.hr_inicio ||
                                this.hr_inicio > item.hr_fim
                            )
                        ) {
                            invalid = true;
                        }
                    }
                });
            });
            if (!invalid) {
                if (this.model.obj_horario) {
                    const auxIndex = this.model.hr_atendimento.findIndex(
                        item => item.uid == this.model.obj_horario.uid
                    );
                    this.model.hr_atendimento[auxIndex] = {
                        ar_dias_semana: this.ar_dias_semana,
                        hr_fim: this.hr_fim,
                        hr_inicio: this.hr_inicio,
                        nr_vagas: this.nr_vagas,
                        id: this.model.hr_atendimento[auxIndex].id,
                        uid: this.model.hr_atendimento[auxIndex].uid
                    };
                } else
                    this.model.hr_atendimento.push({
                        ar_dias_semana: this.ar_dias_semana,
                        hr_fim: this.hr_fim,
                        hr_inicio: this.hr_inicio,
                        nr_vagas: this.nr_vagas,
                        id: null,
                        uid: this.model.next_uid
                    });
                this.model.next_uid += 1;
                this.reset();
                this.$emit("saved");
            } else {
                this.$toast.error(
                    "Horário Inválido. Verifique os horários vigentes",
                    { duration: 4000 }
                );
                return 0;
            }
        },
        reset() {
            this.submitted = false;
            this.ar_dias_semana = [];
            this.hr_fim = null;
            this.hr_inicio = null;
            this.nr_vagas = 0;
            this.model.obj_horario = null;
        },
        convertHour(hour) {
            var auxHour = moment().format("MM-DD-YYYY HH:mm");
            const aux = auxHour.slice(0, -5) + hour;
            return aux;
        },
        handleStart() {
            if (
                +this.hr_inicio.slice(0, 2) > 23 ||
                +this.hr_inicio.slice(-2) > 59
            )
                this.start_error = "Hora inválida";
            else this.start_error = null;
        },
        handleEnd() {
            if (
                +this.hr_fim.slice(0, 2) > 24 ||
                +this.hr_fim.slice(-2) > 59 ||
                +this.hr_fim.slice(0, 2) < +this.hr_inicio.slice(0, 2)
            )
                this.end_error = "Hora inválida";
            else this.end_error = null;
        }
    }
};
</script>

<style lang="scss">
.adicionar-area-atuacao {
    width: 96%;
    max-width: 600px;

    .waiting-add {
        margin-right: 10px;
        width: 25px;
        height: auto;
    }
}
</style>
